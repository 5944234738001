import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './containers';
import * as serviceWorker from './serviceWorker';
import './assets/scss/main.scss';

document.documentElement.setAttribute(
  'lang',
  localStorage.getItem('lang') || 'et',
);

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
