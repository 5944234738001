import { navigate } from '@reach/router';

export function toMain() {
  return navigate('/');
}

export function toFloorSelect() {
  return navigate('/#floor-plan');
}

export function toFloor(floor, number) {
  return navigate(`/${floor}/` + number);
}

export function toRoom(floor, floorNumber, roomNumber, hash) {
  return navigate(
    `/${floor}/${floorNumber}/${roomNumber}${hash ? `#${hash}` : ''}`,
  );
}

export function toPriceList(priceList) {
  return navigate('/' + priceList);
}
