import React, { useState, useEffect } from 'react';
import { Router, globalHistory } from '@reach/router';

import { Sidenav } from '../components';
import {
  Main,
  PriceList,
  // Floor,
  // Room
} from '.';
import ApiService from '../common/api';
import { translate } from '../common/helpers';

const App = () => {
  const [translations, setTranslations] = useState([]);
  const [activeSection, setActiveSection] = useState('first');
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const onSectionChange = (value) => setActiveSection(value);

  useEffect(() => {
    ApiService.getTranslations().then((res) => setTranslations(res));
  }, []);

  useEffect(() => {
    globalHistory.listen(({ action, location }) => {
      if (action === 'PUSH') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'Pageview',
          pagePath: location.pathname,
        });
      }
    });
  }, []);

  const t = (key) => {
    return translate(translations, key);
  };

  const paths = {
    main: '/',
    priceList: '/' + t('URL_PRICE_LIST'),
    floors: `/${t('URL_FLOOR')}/:floor`,
    room: `/${t('URL_FLOOR')}/:floor/:room`,
  };

  const available_languages = [
    { hook: 'et', label: t('NAV_LANG_EE') },
    { hook: 'en', label: t('NAV_LANG_EN') },
    { hook: 'ru', label: t('NAV_LANG_RU') },
  ];

  if (translations.length === 0) return null;

  return (
    <>
      <Sidenav
        active={activeSection}
        isOpen={menuOpen}
        onToggle={toggleMenu}
        languages={available_languages}
        translations={translations}
      />
      <Router>
        <Main
          isMenuOpen={menuOpen}
          onMenuToggle={toggleMenu}
          onSectionChange={onSectionChange}
          path={paths.main}
          translations={translations}
          default
        />
        <PriceList
          path={paths.priceList}
          isMenuOpen={menuOpen}
          onMenuToggle={toggleMenu}
          translations={translations}
        />
        {/* <Floor
          path={paths.floors}
          isMenuOpen={menuOpen}
          onMenuToggle={toggleMenu}
          translations={translations}
        />
        <Room
          path={paths.room}
          isMenuOpen={menuOpen}
          onMenuToggle={toggleMenu}
          translations={translations}
        /> */}
      </Router>
    </>
  );
};

export default App;
