import React, { useState } from 'react';
import Popover from 'react-popover';

import Grid from '../Grid/Grid';
import { translate } from '../../common/helpers';

const Footer = ({ translations }) => {
  const [popover, setPopover] = useState();

  const t = (key) => {
    return translate(translations, key);
  };

  const pages = [
    {
      name: t('FOOTER_PROMO_1_NAME'),
      desc: t('FOOTER_PROMO_1_DESCRIPTION'),
      image: t('FOOTER_PROMO_1_LOGO'),
      link: t('FOOTER_PROMO_1_LINK'),
      isActive: t('FOOTER_PROMO_1_ACTIVE'),
    },
    {
      name: t('FOOTER_PROMO_2_NAME'),
      desc: t('FOOTER_PROMO_2_DESCRIPTION'),
      image: t('FOOTER_PROMO_2_LOGO'),
      link: t('FOOTER_PROMO_2_LINK'),
      isActive: t('FOOTER_PROMO_2_ACTIVE'),
    },
    {
      name: t('FOOTER_PROMO_3_NAME'),
      desc: t('FOOTER_PROMO_3_DESCRIPTION'),
      image: t('FOOTER_PROMO_3_LOGO'),
      link: t('FOOTER_PROMO_3_LINK'),
      isActive: t('FOOTER_PROMO_3_ACTIVE'),
    },
    {
      name: t('FOOTER_PROMO_4_NAME'),
      desc: t('FOOTER_PROMO_4_DESCRIPTION'),
      image: t('FOOTER_PROMO_4_LOGO'),
      link: t('FOOTER_PROMO_4_LINK'),
      isActive: t('FOOTER_PROMO_4_ACTIVE'),
    },
    {
      name: t('FOOTER_PROMO_5_NAME'),
      desc: t('FOOTER_PROMO_5_DESCRIPTION'),
      image: t('FOOTER_PROMO_5_LOGO'),
      link: t('FOOTER_PROMO_5_LINK'),
      isActive: t('FOOTER_PROMO_5_ACTIVE'),
    },
    {
      name: t('FOOTER_PROMO_6_NAME'),
      desc: t('FOOTER_PROMO_6_DESCRIPTION'),
      image: t('FOOTER_PROMO_6_LOGO'),
      link: t('FOOTER_PROMO_6_LINK'),
      isActive: t('FOOTER_PROMO_6_ACTIVE'),
    },
    {
      name: t('FOOTER_PROMO_7_NAME'),
      desc: t('FOOTER_PROMO_7_DESCRIPTION'),
      image: t('FOOTER_PROMO_7_LOGO'),
      link: t('FOOTER_PROMO_7_LINK'),
      isActive: t('FOOTER_PROMO_7_ACTIVE'),
    },
  ];

  const Image = ({ page, index }) => (
    <img
      src={page.image}
      alt={page.name}
      onMouseOver={() => setPopover(index)}
      onMouseOut={() => setPopover(null)}
      loading="lazy"
    />
  );

  return (
    <footer className="p-t-100 p-b-80" style={{ backgroundColor: '#222229' }}>
      <div className="container">
        <Grid>
          <Grid.Col width="sm-3" className="text-center">
            <a
              href={t('FOOTER_LINK_LINK')}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={t('FOOTER_LOGO')} alt="Novira Capital" loading="lazy" />
            </a>
          </Grid.Col>
          <Grid.Col width="sm-9">
            <Grid>
              <Grid.Col class="d-flex justify-content-center" width="sm-12">
                <p className="text-center m-b-40 p-s-30">
                  {t('FOOTER_TEXT')}{' '}
                  <a
                    className="f-bold"
                    href={t('FOOTER_LINK_LINK')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('FOOTER_LINK_TEXT')}
                  </a>
                </p>
              </Grid.Col>
            </Grid>
            <Grid align="center-sm">
              {pages
                .filter((i) => i.isActive === 'true')
                .map((page, index) => (
                  <React.Fragment key={index}>
                    <Popover
                      preferPlace="left"
                      isOpen={popover === page.name}
                      body={renderPopover(page, t)}
                    >
                      <Grid.Col
                        width="sm-3"
                        align="middle-sm"
                        className="text-center m-b-60"
                      >
                        {!!page.link ? (
                          <a
                            href={page.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Image page={page} index={page.name} />
                          </a>
                        ) : (
                          <Image page={page} index={page.name} />
                        )}
                      </Grid.Col>
                    </Popover>
                  </React.Fragment>
                ))}
            </Grid>
            <Grid align="center-sm">
              {pages
                .filter((i) => i.isActive === 'false')
                .map((page, index) => (
                  <React.Fragment key={index}>
                    <Popover
                      preferPlace="left"
                      isOpen={popover === page.name}
                      body={renderPopover(page, t)}
                    >
                      <Grid.Col
                        width="sm-3"
                        align="middle-sm"
                        className="text-center m-b-60"
                      >
                        {!!page.link ? (
                          <a
                            href={page.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Image page={page} index={page.name} />
                          </a>
                        ) : (
                          <Image page={page} index={page.name} />
                        )}
                      </Grid.Col>
                    </Popover>
                  </React.Fragment>
                ))}
            </Grid>
          </Grid.Col>
        </Grid>
      </div>
    </footer>
  );
};

function renderPopover(page, t) {
  return (
    <div className="popover popover--small">
      {page.isActive === 'true' && (
        <p className="f-smallest">{t('FOOTER_ACTIVE')}</p>
      )}
      {page.isActive === 'false' && (
        <p className="f-smallest">{t('FOOTER_INACTIVE')}</p>
      )}
      <p className="f-small m-t-15">
        <strong>{page.name}</strong>
        {` - ${page.desc}`}
      </p>
      {page.isActive === 'true' && !!page.link && (
        <p className="f-smallest m-t-15">
          <em>{page.link.replace(/(^\w+:|^)\/\//, 'www.')}</em>
        </p>
      )}
      <PopoverCard className="popover__card" />
    </div>
  );
}

function PopoverCard({ className }) {
  return (
    <svg className={className} preserveAspectRatio="none" viewBox="0 0 260 123">
      <path
        fill="#FACFBC"
        d="M0 2.498A2.493 2.493 0 0 1 2.49 0h232.843c1.375 0 2.866 1.051 3.333 2.357l20.492 57.355c.465 1.302.457 3.415-.014 4.708l-20.465 56.213c-.473 1.298-1.972 2.35-3.342 2.35H2.5a2.5 2.5 0 0 1-2.5-2.498V2.498z"
      />
    </svg>
  );
}

export default Footer;
