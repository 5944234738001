import React, { useState } from 'react';
import Popover from 'react-popover';

import { translate } from '../../common/helpers';
import compass from '../../assets/images/compass.svg';

export default class FloorPlan extends React.Component {
  render() {
    const { onSelect, active, floorData } = this.props;

    return (
      <div className="floor-plan">
        <FloorSvg
          onSelect={onSelect}
          floorData={floorData[active + 1]}
          translations={this.props.translations}
        />
      </div>
    );
  }
}

function FloorSvg({ onSelect, floorData, translations }) {
  const [floorPopover, setFloorPopover] = useState();

  return (
    <div className="floor-plan__floor">
      <img
        src={floorData.mainFloorImage}
        alt="floor 5 background"
        className="floor-plan__image"
        loading="lazy"
      />
      <img src={compass} alt="Compass" className="floor-plan__legend" loading="lazy" />
      <svg version="1.0" viewBox={`0 0 ${floorData.svgX} ${floorData.svgY}`}>
        {floorData.areas.map((room) => (
          <Popover
            key={room.number}
            preferPlace="left"
            isOpen={floorPopover === room.number}
            body={renderPopover(room, translations)}
          >
            <g
              className={room.status === 'booked' ? 'is-booked' : ''}
              disabled={['sold', 'rented'].includes(room.status)}
              onClick={() =>
                !['sold', 'rented'].includes(room.status) &&
                onSelect(room.number)
              }
              onMouseOver={() =>
                !['sold', 'rented'].includes(room.status) &&
                setFloorPopover(room.number)
              }
              onMouseOut={() =>
                !['sold', 'rented'].includes(room.status) &&
                setFloorPopover(null)
              }
              id={`pind${room.number}`}
              dangerouslySetInnerHTML={createMarkup(room.svgShape)}
            />
          </Popover>
        ))}
      </svg>
    </div>
  );
}

function PopoverCard({ className }) {
  return (
    <svg className={className} preserveAspectRatio="none" viewBox="0 0 260 123">
      <path
        fill="#FACFBC"
        d="M0 2.498A2.493 2.493 0 0 1 2.49 0h232.843c1.375 0 2.866 1.051 3.333 2.357l20.492 57.355c.465 1.302.457 3.415-.014 4.708l-20.465 56.213c-.473 1.298-1.972 2.35-3.342 2.35H2.5a2.5 2.5 0 0 1-2.5-2.498V2.498z"
      />
    </svg>
  );
}

function renderPopover(room, translations) {
  const t = (key) => {
    return translate(translations, key);
  };

  return (
    <div className="popover">
      <p className="f-bold f-smallest">
        {t(`AREA_TYPE_${room.type.toUpperCase()}`)} {t('AREA_NUMBER')}{' '}
        {room.number}
      </p>
      <div className="popover__props">
        <p className="f-tiny">
          <span className="f-bold">{t('AREA_AREA')}: </span>
          {`${room.area} ${
            room.terrace !== '-'
              ? `+ ${t('PRICE_LIST_TERRACE').toLowerCase()} ${room.terrace}`
              : ''
          }`}
        </p>
      </div>
      <PopoverCard className="popover__card" />
    </div>
  );
}

function createMarkup(code) {
  return {
    __html: code,
  };
}
