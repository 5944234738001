import React from 'react';
import PropTypes from 'prop-types';

const Help = ({ children, variant }) => {
  let classes = 'help';
  if (variant) classes += ` help--${variant}`;

  return <p className={classes}>{children}</p>;
};

Help.propTypes = {
  variant: PropTypes.oneOf(['error']),
};

export default Help;
