import React from 'react';
import { Field, Control, Label } from './components';

const Textarea = ({ label, ...rest }) => (
  <Field>
    <Label>{label}</Label>
    <Control>
      <textarea className="textarea" {...rest} />
    </Control>
  </Field>
);

export default Textarea;
