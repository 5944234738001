import React, { Component } from 'react';
import Slider from 'react-slick';

import { translate } from './../../common/helpers';

export default class Carousel extends Component {
  t(key) {
    return translate(this.props.translations, key);
  }

  render() {
    const { images, reference } = this.props;

    const Arrow = ({ direction, onClick }) => {
      const pause = (e) => {
        this.slider.slickPause();
        onClick();
      };

      return (
        <button className={`slick-arrow slick-${direction}`} onClick={pause} />
      );
    };

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      pauseOnHover: false,
      prevArrow: <Arrow direction="prev" />,
      nextArrow: <Arrow direction="next" />,
    };

    return (
      <div id={reference.id} ref={reference.ref} className="carousel">
        <img src={this.t('MAIN_LOGO')} className="carousel__label" alt="" loading="lazy" />
        <Slider {...settings} ref={(c) => (this.slider = c)}>
          {images.map((url) => (
            <div key={url} className="carousel__figure">
              <img src={url} className="carousel__image" alt="" loading="lazy" />
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
