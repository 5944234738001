import React from 'react';
import { Field, Control, Label, Help } from './components';

const Input = ({ label, ...rest }) => (
  <Field>
    <Label>{label}</Label>
    <Control>
      <input className="input" {...rest} />
    </Control>
    {rest.error && <Help>{rest.error}</Help>}
  </Field>
);

export default Input;
