import React from 'react';

import { scrollTo, translate } from '../../common/helpers';
import { toPriceList } from '../../common/navigator';
import Sidemenu from '../Sidemenu/Sidemenu';
import Languages from '../Languages/Languages';
import { navigate } from '@reach/router';

const Sidenav = ({ active, isOpen, onToggle, languages, translations }) => {
  const t = (key) => {
    return translate(translations, key);
  };

  const sections = [
    { id: 'location', text: t('NAV_LOCATION') },
    // { id: 'floor-plan', text: t('NAV_FLOOR_PLAN') },
    { id: 'style', text: t('NAV_STYLE') },
    { id: 'price-list', text: t('NAV_PRICE_LIST'), router: toPriceList },
    { id: 'gallery', text: t('NAV_GALLERY') },
    { id: 'sale-form', text: t('NAV_SALE') },
  ];

  const handleOnClick = (el) => (e) => {
    e.preventDefault();

    if (!!el.router) {
      el.router(t('URL_PRICE_LIST'));
      onToggle();
      return;
    }

    if (window.location.pathname !== '/') {
      onToggle();
      navigate(`/#${el.id}`);
      return;
    }

    scrollTo(`#${el.id}`);
    onToggle();
  };

  return (
    <Sidemenu isOpen={isOpen} side="right">
      <div className="sidenav__container">
        <ul className="sidenav__nav">
          {sections.map((el) => (
            <li key={el.id}>
              <a
                className={`sidenav__nav-link ${
                  (window.location.pathname === '/' && active === el.id) ||
                  (/hinnakiri|price-list/.test(window.location.pathname) &&
                    el.id === 'price-list')
                    ? 'is-active'
                    : ''
                }`}
                onClick={handleOnClick(el)}
                href={`#${el.id}`}
              >
                {el.text}
              </a>
            </li>
          ))}
        </ul>

        <Languages languages={languages} />
      </div>
    </Sidemenu>
  );
};

export default Sidenav;
