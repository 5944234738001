import React from 'react';

import map from '../../assets/images/map.svg';
import { translate } from '../../common/helpers';

const Map = ({ translations }) => {
  const t = (key) => {
    return translate(translations, key);
  };

  return (
    <section className="map__container">
      <h2 className="sr-only">Map</h2>
      <div className="map" style={{ backgroundImage: `url(${map}` }}>
        <img className="map__mobile-image" src={map} alt="map" loading="lazy" />
        <span className="map__tooltip">
          {t('SECTION_MAP_MAPS_1')}{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={t('SECTION_MAP_MAPS_LINK')}
          >
            {t('SECTION_MAP_MAPS_2')}
          </a>{' '}
        </span>
      </div>
    </section>
  );
};

export default Map;
