export const makeFetch = async (url, options) => {
  try {
    const response = await window.fetch(url, options);
    return await response.json();
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

const BASE_URL = '/wp-json/acf/v3';

class ApiService {
  lang =
    window.location.search.split('=')[1] ||
    localStorage.getItem('lang') ||
    'et';
  langSearch = `lang=${this.lang}`;

  getTranslations() {
    return makeFetch(
      `${BASE_URL}/translation?per_page=1000&${this.langSearch}`,
    ).then((res) => {
      return res.map((r) => ({
        ...r.acf,
      }));
    });
  }

  getFloors() {
    return makeFetch(`${BASE_URL}/floor?per_page=1000`);
  }

  getAreas() {
    return makeFetch(`${BASE_URL}/area?per_page=1000`);
  }

  getCarouselImages() {
    return makeFetch(`${BASE_URL}/image?per_page=1000`);
  }

  getArea(number) {
    return this.getAreas().then((areas) =>
      areas.find((x) => x.acf.number === number),
    );
  }

  sendEmail(body) {
    return makeFetch('/wp-json/email/send', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}

export default new ApiService();
