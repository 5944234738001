import React from 'react';
import classNames from 'classnames';

const Button = ({ children, block, small, className, link, icon, ...rest }) => {
  const classes = classNames('button', className, {
    'button--block': block,
    'button--small': small,
    'button--icon': icon,
  });

  const Element = link ? 'a' : 'button';

  return (
    <Element type="button" className={classes} {...rest}>
      {children}
    </Element>
  );
};

export default Button;
