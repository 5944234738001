import React from 'react';

import openMenuIcon from '../../assets/images/menu-open-white.svg';
import closeMenuIcon from '../../assets/images/menu-close.svg';

const MenuButton = ({ isOpen, onToggle, fixed, isWhite, className }) => (
  <button
    onClick={() => onToggle()}
    type="button"
    className={`menu-button ${fixed && 'is-fixed'} ${className}`}
  >
    <img
      src={isOpen ? closeMenuIcon : openMenuIcon}
      alt="menu"
      className={`menu-button__image ${!isWhite && !isOpen ? 'black' : ''}`}
      loading="lazy"
    />
  </button>
);

export default MenuButton;
