import React from 'react';

import { Logo } from '..';

const Section = ({
  children,
  hasLogo,
  noSpacing,
  backgroundImage,
  reference,
  contentReference,
  isFixed,
  zIndex,
  logo,
  isVisible,
  content,
  bgStyles,
}) => {
  const styles = {
    backgroundImage:
      backgroundImage &&
      `${bgStyles ? `${bgStyles}, ` : ''}url(${backgroundImage}`,
    zIndex,
  };

  return (
    <section
      style={styles}
      id={(reference || {}).id}
      ref={(reference || {}).ref}
      className="section"
    >
      {!noSpacing && (
        <div
          className={`section__spacing ${
            isFixed ? 'section__spacing--no-mobile' : ''
          }`}
        >
          {hasLogo && <Logo logo={logo} />}
          {!isFixed && (
            <img
              src={backgroundImage}
              className="section__mobile-image"
              alt="Background"
              loading="lazy"
            />
          )}
        </div>
      )}
      <div
        className={`section__content ${isFixed ? 'is-fixed' : ''} ${
          !isVisible && isFixed ? 'hidden' : ''
        }`}
        id={(contentReference || {}).id}
        ref={(contentReference || {}).ref}
      >
        {content}
        <div className="container">{children}</div>
      </div>
    </section>
  );
};

export default Section;
