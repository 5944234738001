export const scrollTo = (id) => {
  document.querySelector(id).scrollIntoView({
    block: 'start',
    behavior: 'smooth',
  });
};

export const translate = (translations, key) => {
  const translation = (translations || []).find((x) => x.key === key);
  if (!translation) return null;
  return translation.translation;
};
