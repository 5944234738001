import React from 'react';
import 'intersection-observer';
import maxBy from 'lodash/maxBy';

// import { toFloor } from './../../common/navigator';
import { scrollTo, translate } from './../../common/helpers';
import ApiController from './../../common/api';

import {
  Section,
  Grid,
  Button,
  Map,
  // FloorSelect,
  Carousel,
  Footer,
  ContactForm,
  MenuButton,
} from '../../components';

class Main extends React.Component {
  constructor(props) {
    super(props);

    const sections = ['location', 'style', 'gallery', 'sale'];

    this.state = {
      refs: sections.reduce(
        (o, key) => ({ ...o, [key]: { ref: React.createRef(), id: key } }),
        {},
      ),
      current: sections[0],
      currentRatio: 0,
      carouselImages: [],
    };

    this.onIntersect = (entries) => {
      const maxEntry = maxBy(entries, (e) => e.intersectionRatio);
      this.state.current === maxEntry.target.id
        ? maxEntry.intersectionRatio > this.state.currentRatio &&
          this.setState({
            ...this.state,
            currentRatio: maxEntry.intersectionRatio,
          })
        : maxEntry.intersectionRatio > 0.24 &&
          this.setState({
            ...this.state,
            current: maxEntry.target.id,
            currentRatio: maxEntry.intersectionRatio,
          });
      return this.props.onSectionChange(this.state.current);
    };
  }

  componentDidMount() {
    ApiController.getCarouselImages().then((x) =>
      this.setState({
        ...this.state,
        carouselImages: x.map((x) => (x.acf || {}).image),
      }),
    );

    !!window.location.hash &&
      document.querySelector(window.location.hash).scrollIntoView();
    Object.values(this.state.refs).forEach((value) => {
      const observer = new IntersectionObserver(this.onIntersect, {
        root: null,
        rootMargin: '0px',
        threshold: value.id === 'gallery' ? 1 : [0, 0.25, 0.5, 0.75, 1],
      });
      observer.observe(value.ref.current);
    });
  }

  t(key) {
    return translate(this.props.translations, key);
  }

  render() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    return (
      <>
        <h1 className="sr-only">
          Büroo31 - {this.t('SECTION_LOCATION_HEADER')}
        </h1>
        <MenuButton
          fixed
          onToggle={this.props.onMenuToggle}
          isOpen={this.props.isMenuOpen}
          isWhite={this.props.isWhiteMenuButton}
        />
        <Section
          contentReference={this.state.refs['location']}
          backgroundImage={this.t('MAIN_BACKGROUND_1')}
          hasLogo
          logo={this.t('MAIN_LOGO')}
          content={
            <div className="container container--hero text-center text-shadow">
              <h2 className="h1+ color-white m-b-10">{this.t('HERO_TITLE')}</h2>
              <h2 className="h1+ m-t-0">{this.t('HERO_SUBTITLE')}</h2>
              <Button
                link
                className="button--inverse"
                href={`/${this.t('URL_PRICE_LIST')}`}
              >
                {this.t('HERO_CTA')}
              </Button>
            </div>
          }
          bgStyles="linear-gradient(to bottom, rgba(34, 34, 41, .3), rgba(34, 34, 41, .3))"
        >
          <Grid align="bottom-sm">
            <Grid.Col width="md-auto m-r-100 p-r-20">
              <h2 className="h1">{this.t('SECTION_LOCATION_HEADER')}</h2>
              <h4>{this.t('SECTION_LOCATION_SUBHEADER')}</h4>
              <p>{this.t('SECTION_LOCATION_CONTENT_1')}</p>
              <p>{this.t('SECTION_LOCATION_CONTENT_2')}</p>
              <p>{this.t('SECTION_LOCATION_CONTENT_3')}</p>
              <p>{this.t('SECTION_LOCATION_CONTENT_4')}</p>
              <p>{this.t('SECTION_LOCATION_CONTENT_5')}</p>
              <Button
                link
                href={this.t('SECTION_LOCATION_BUTTON_PDF_LINK')}
                target="_blank"
                rel="noopener noreferrer"
                className="m-t-20 hoone-tehniline-info-pdf"
              >
                {this.t('SECTION_LOCATION_BUTTON_PDF')}
              </Button>
            </Grid.Col>
            <Grid.Col width="md">
              <ul className="list-unstyled color-beige">
                <li className="h4 f-montserrat letter-spacing-1-2 m-0">
                  <strong>{this.t('SECTION_LOCATION_RETAIL_COUNT')}</strong>{' '}
                  {this.t('SECTION_LOCATION_RETAIL')}
                </li>
                <li className="h4 f-montserrat letter-spacing-1-2 m-0">
                  <strong>{this.t('SECTION_LOCATION_OFFICE_COUNT')}</strong>{' '}
                  {this.t('SECTION_LOCATION_OFFICE')}
                </li>
              </ul>
              <Button
                block
                onClick={() => scrollTo('#sale-form')}
                className="m-t-40 m-b-40 mine-saada-paring-vaatesse"
              >
                {this.t('SECTION_LOCATION_BUTTON_EMAIL')}
              </Button>
            </Grid.Col>
          </Grid>
        </Section>

        <Map
          reference={this.state.refs['map']}
          translations={this.props.translations}
        />

        {/* <FloorSelect
          reference={this.state.refs['floor-plan']}
          onSelect={(n) => toFloor(this.t('URL_FLOOR'), n + 1)}
          background={this.t('MAIN_BACKGROUND_4')}
        /> */}

        <Section
          reference={this.state.refs['style']}
          backgroundImage={this.t('MAIN_BACKGROUND_2')}
          // isFixed
          // zIndex="-1"
          // isVisible={['location', 'floor-plan', 'style'].includes(
          //   this.state.current,
          // )}
        >
          <h1 className="text-center">{this.t('SECTION_STYLE_HEADER')}</h1>
          <Grid align="between-xs">
            <Grid.Col width="sm-4">
              <h2>{this.t('SECTION_STYLE_SUBHEADER_LEFT')}</h2>
              <p className="letter-spacing-negative">
                {this.t('SECTION_STYLE_DESCRIPTION_LEFT')}
              </p>
            </Grid.Col>
            <Grid.Col width="sm-4">
              <h2>{this.t('SECTION_STYLE_SUBHEADER_RIGHT')}</h2>
              <p className="letter-spacing-negative">
                {this.t('SECTION_STYLE_DESCRIPTION_RIGHT')}
              </p>
            </Grid.Col>
          </Grid>
          <Grid align="between-xs" className="m-b-40">
            <Grid.Col width="sm-4">
              <Button
                link
                block
                href={this.t('SECTION_STYLE_BUTTON_LEFT_LINK')}
                target="_blank"
                rel="noopener noreferrer"
                className="hele-viimistlus-pdf"
              >
                {this.t('SECTION_STYLE_BUTTON_LEFT')}
              </Button>
            </Grid.Col>
            <Grid.Col width="sm-4">
              <Button
                link
                block
                href={this.t('SECTION_STYLE_BUTTON_RIGHT_LINK')}
                target="_blank"
                rel="noopener noreferrer"
                className="tume-viimistlus-pdf"
              >
                {this.t('SECTION_STYLE_BUTTON_RIGHT')}
              </Button>
            </Grid.Col>
          </Grid>
        </Section>

        <section className="section">
          <div className="section__content section__content--no-flex">
            <h2 className="sr-only">Galerii</h2>
            <Carousel
              reference={this.state.refs['gallery']}
              images={this.state.carouselImages}
              translations={this.props.translations}
            />
          </div>
        </section>

        <Section
          reference={this.state.refs['sale']}
          backgroundImage={this.t('MAIN_BACKGROUND_3')}
          // noSpacing
          // isFixed
          // zIndex="-1"
          isVisible={['sale', 'rent'].includes(this.state.current)}
          contentReference={{ id: 'sale-form' }}
        >
          <h1 className="m-b-60">{this.t('SECTION_SALE_HEADER')}</h1>
          <Grid align="between-sm">
            <Grid.Col width="sm-6">
              <Grid align="between-sm,bottom-sm">
                <Grid.Col width="sm-6">
                  <h2 className="m-t-0">{this.t('SECTION_SALE_NAME_LEFT')}</h2>
                  <p className="h5 f-montserrat m-0">
                    <a
                      href={`mailto:${this.t('SECTION_SALE_EMAIL_LEFT')}`}
                      className="color-white"
                    >
                      {this.t('SECTION_SALE_EMAIL_LEFT')}
                    </a>
                  </p>
                  <p className="h5 f-montserrat m-t-0 m-b-30">
                    <a
                      href={`tel:${this.t('SECTION_SALE_PHONE_LEFT')}`}
                      className="color-white"
                    >
                      {this.t('SECTION_SALE_PHONE_LEFT')}
                    </a>
                  </p>
                  <img
                    src={this.t('SECTION_SALE_IMAGE_LEFT')}
                    alt={this.t('SECTION_SALE_NAME_LEFT')}
                    loading="lazy"
                  />
                </Grid.Col>
                <Grid.Col width="sm-6">
                  <h2 className="m-t-0">{this.t('SECTION_SALE_NAME_RIGHT')}</h2>
                  <p className="h5 f-montserrat m-0">
                    <a
                      href={`mailto:${this.t('SECTION_SALE_EMAIL_RIGHT')}`}
                      className="color-white"
                    >
                      {this.t('SECTION_SALE_EMAIL_RIGHT')}
                    </a>
                  </p>
                  <p className="h5 f-montserrat m-t-0 m-b-30">
                    <a
                      href={`tel:${this.t('SECTION_SALE_PHONE_RIGHT')}`}
                      className="color-white"
                    >
                      {this.t('SECTION_SALE_PHONE_RIGHT')}
                    </a>
                  </p>
                  <img
                    src={this.t('SECTION_SALE_IMAGE_RIGHT')}
                    alt={this.t('SECTION_SALE_NAME_RIGHT')}
                    loading="lazy"
                  />
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col width="sm-4">
              <ContactForm buttonRight translations={this.props.translations} />
            </Grid.Col>
          </Grid>
        </Section>

        <Section backgroundImage={this.t('MAIN_BACKGROUND_3')} noSpacing>
          <h1 className="letter-spacing-negative-2x">
            {this.t('SECTION_CONDITIONS_HEADER')}
          </h1>
          <h4>{this.t('SECTION_CONDITIONS_SUBHEADER')}</h4>
          <Grid align="between-sm">
            <Grid.Col width="sm-5">
              <h4 className="uppercase">
                {this.t('SECTION_CONDITIONS_1_HEADER')}
              </h4>
              <p className="f-small m-b-40">
                {this.t('SECTION_CONDITIONS_1_CONTENT')}
              </p>
              {/* <h4 className="uppercase">
                {this.t('SECTION_CONDITIONS_2_HEADER')}
              </h4>
              <p className="f-small m-b-40">
                {this.t('SECTION_CONDITIONS_2_CONTENT')}
              </p> */}
              <h4 className="uppercase">
                {this.t('SECTION_CONDITIONS_3_HEADER')}
              </h4>
              <p className="f-small">
                {this.t('SECTION_CONDITIONS_3_CONTENT')}
              </p>
            </Grid.Col>
            <Grid.Col width="sm-5">
              <h4 className="uppercase">
                {this.t('SECTION_CONDITIONS_4_HEADER')}
              </h4>
              <ul className="p-l-20 m-b-40">
                {this.props.translations
                  .filter((t) =>
                    t.key.includes('SECTION_CONDITIONS_4_CONTENT_'),
                  )
                  .map((k) => (
                    <li key={k.key}>{k.translation}</li>
                  ))}
              </ul>
              <h4 className="uppercase">
                {this.t('SECTION_CONDITIONS_5_HEADER')}
              </h4>
              <ul className="p-l-20 m-b-40">
                {this.props.translations
                  .filter((t) =>
                    t.key.includes('SECTION_CONDITIONS_5_CONTENT_'),
                  )
                  .map((k) => (
                    <li key={k.key}>{k.translation}</li>
                  ))}
              </ul>
              <p className="f-smallest">
                <span className="f-bold">
                  {this.t('SECTION_CONDITIONS_DISCLAIMER_NB')}
                </span>{' '}
                {this.t('SECTION_CONDITIONS_DISCLAIMER_CONTENT')}
              </p>
            </Grid.Col>
          </Grid>
        </Section>

        <Footer translations={this.props.translations} />
      </>
    );
  }
}

export default Main;
